import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth, Hub } from "aws-amplify";
import moment, { Moment } from "moment";
import React, {
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

import { CustomerIndex, GetTaxonomyNodeExampleConversationsResponse } from "./indexTypes";
import { customerIndexes } from "./reportApi";
import {
  FilterOptions,
  Taxonomy,
  TaxonomyNodeData,
  TaxonomyTimeseriesMetadata,
  View,
} from "./types/dashboardTypes";
import { ParsedExpression } from "./types/expressionsDslTypes";

export type CustomerUIModel = {
  id: string;
  index: CustomerIndex;
};

export const isAdmin = (user: CognitoUser | null) => {
  const userPayload = user?.getSignInUserSession()?.getAccessToken().decodePayload();
  if (!userPayload || !userPayload["cognito:groups"]) {
    return false;
  }
  return userPayload["cognito:groups"].includes("admin") ?? false;
};

export const useCurrentUser = () => {
  const [user, setUser] = useState<CognitoUser | null>(null);

  useEffect(() => {
    async function updateUser() {
      try {
        setUser(await Auth.currentAuthenticatedUser());
      } catch {
        setUser(null);
      }
    }
    Hub.listen("auth", updateUser);
    updateUser();
    return () => Hub.remove("auth", updateUser);
  }, []);

  return user;
};

export const useCustomerGroups = () => {
  const [customer, setCustomer] = useState<CustomerUIModel>();
  const [customers, setCustomers] = useState<CustomerUIModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const currentUser = useCurrentUser();
  const userId = currentUser?.getUsername();

  const unsetCustomer = useCallback(() => {
    setCustomer(undefined);
    localStorage.removeItem("chosenCustomerId");
  }, [setCustomer]);

  useEffect(() => {
    async function getJson() {
      setIsLoading(true);

      setCustomers(await customerIndexes());
      setIsLoading(false);
    }

    if (userId) {
      getJson();
    }
  }, [userId]);

  useEffect(() => {
    if (customers.length === 1) {
      setCustomer(customers[0]);
    }
  }, [customers]);

  return { customer, customers, setCustomer, unsetCustomer, isLoading };
};

type CustomerInfo = {
  customer: CustomerUIModel;
  customers: CustomerUIModel[];
  setCustomer: React.Dispatch<SetStateAction<CustomerUIModel | undefined>>;
  unsetCustomer: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const customerContext = createContext(undefined as any);
export const useCustomer = () => useContext(customerContext) as CustomerInfo;

export interface DashboardState {
  viewLoading: boolean;
  taxonomyLoading: boolean;
  taxonomyNodeListLoading: boolean;
  error?: string;
  startDate: Moment;
  endDate: Moment;
  granularity: string;
  taxonomy?: Taxonomy;
  metadataAnalysisChoice?: string;
  taxonomyTimeseriesMetadata?: TaxonomyTimeseriesMetadata;
  taxonomyNodeData: TaxonomyNodeData[];
  view?: View;
  filterOptions: FilterOptions | undefined;
  filters?: ParsedExpression | undefined;
}

type DashboardAction =
  | { type: "SET_VIEW_LOADING"; payload: boolean }
  | { type: "SET_TAXONOMY_LOADING"; payload: boolean }
  | { type: "SET_TAXONOMY_NODE_LIST_LOADING"; payload: boolean }
  | { type: "SET_ERROR"; payload: string | undefined }
  | { type: "SET_DATES"; payload: { startDate: Moment; endDate: Moment } }
  | { type: "SET_GRANULARITY"; payload: string }
  | { type: "SET_TAXONOMY"; payload: Taxonomy }
  | { type: "SET_METADATA_ANALYSIS_CHOICE"; payload: string }
  | { type: "SET_VIEW"; payload: View }
  | { type: "SET_TAXONOMY_TIMESERIES"; payload: TaxonomyTimeseriesMetadata }
  | { type: "SET_TAXONOMY_NODE_DATA"; payload: TaxonomyNodeData[] }
  | { type: "SET_FILTER_OPTIONS"; payload: FilterOptions }
  | { type: "SET_FILTERS"; payload: ParsedExpression | undefined }
  | { type: "FETCH_DATA" };

export const initialState: DashboardState = {
  viewLoading: true,
  taxonomyLoading: true,
  taxonomyNodeListLoading: true,
  error: undefined,
  startDate: moment().add(-1, "month"),
  endDate: moment(),
  filterOptions: undefined,
  filters: undefined,
  granularity: "day",
  taxonomy: undefined,
  taxonomyTimeseriesMetadata: undefined,
  taxonomyNodeData: [],
};

export const DashboardContext = React.createContext<{
  state: DashboardState;
  dispatch: React.Dispatch<DashboardAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const getStartAndEndDates = (
  viewStartDate: string | undefined,
  numDays: number | undefined = 30
) => {
  const startDate = viewStartDate ? moment(viewStartDate) : moment().add(-numDays, "day");
  let endDate = viewStartDate ? moment(viewStartDate).add(numDays, "days") : moment();
  if (endDate.isAfter(moment())) {
    endDate = moment();
  }
  return [startDate, endDate];
};

export function dashboardReducer(state: DashboardState, action: DashboardAction): DashboardState {
  switch (action.type) {
    case "SET_VIEW_LOADING":
      return { ...state, viewLoading: action.payload };
    case "SET_TAXONOMY_LOADING":
      return { ...state, taxonomyLoading: action.payload };
    case "SET_TAXONOMY_NODE_LIST_LOADING":
      return { ...state, taxonomyNodeListLoading: action.payload };
    case "SET_ERROR":
      return { ...state, error: action.payload };
    case "SET_DATES":
      return { ...state, startDate: action.payload.startDate, endDate: action.payload.endDate };
    case "SET_GRANULARITY":
      return { ...state, granularity: action.payload };
    case "SET_TAXONOMY":
      return { ...state, taxonomy: action.payload };
    case "SET_METADATA_ANALYSIS_CHOICE":
      return { ...state, metadataAnalysisChoice: action.payload };
    case "SET_VIEW": {
      const [startDate, endDate] = getStartAndEndDates(
        action.payload.settings.defaultStartDate,
        action.payload.settings.defaultPeriodLength
      );
      const defaultMetadataAnalysisChoice = Object.keys(
        action.payload.settings.metadataAnalysisOptions
      ).find(key => action.payload.settings.metadataAnalysisOptions[key].is_default);
      return {
        ...state,
        view: action.payload,
        startDate,
        endDate,
        metadataAnalysisChoice: defaultMetadataAnalysisChoice,
      };
    }
    case "SET_TAXONOMY_TIMESERIES":
      return { ...state, taxonomyTimeseriesMetadata: action.payload };
    case "SET_TAXONOMY_NODE_DATA":
      return { ...state, taxonomyNodeData: action.payload, taxonomyNodeListLoading: false };
    case "SET_FILTER_OPTIONS":
      return { ...state, filterOptions: action.payload };
    case "SET_FILTERS":
      return { ...state, filters: action.payload };
    case "FETCH_DATA":
      return {
        ...state,
        taxonomyLoading: true,
        viewLoading: true,
        error: undefined,
        taxonomyTimeseriesMetadata: undefined,
        filters: undefined,
        view: undefined,
      };
  }
}

export const useDashboardContext = () => useContext(DashboardContext);
export const useDashboardReducer = () => useReducer(dashboardReducer, initialState);
