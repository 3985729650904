import { Auth } from "aws-amplify";
import React from "react";
import { SidebarItem, SidebarSubMenu } from "./indexTypes";

const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*(@|\sat\s)(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?(\.|\sdot\s))+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const DOB = new RegExp(
  [
    "(?:\\d{1,2}[-/\\s]\\d{1,2}[-/\\s]'?\\d{2,4})",
    "(?:\\d{2,4}[-/\\s]\\d{1,2}[-/\\s]\\d{1,2})",
    "(?:(?:January|February|March|April|May|June|July|August|September|October|November|December|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sept|Sep|Oct|Nov|Dec)[\\s-/,]*?\\d{1,2}(?:\\s)*(?:rd|th|st)?(?:\\s)*[-/,]?(?:\\s)*'?\\d{2,4})",
    "(?:\\d{1,2}(?:\\s)*(?:rd|th|st)?(?:\\s)*(?:January|February|March|April|May|June|July|August|September|October|November|December|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sept|Sep|Oct|Nov|Dec)(?:\\s)*?[-/,]?(?:\\s)*'?\\d{2,4})",
  ].join("|"),
  "i"
);
const cc = /\b(?:d{4}[ -]?){3}(?=d{4}\b)/;
const ph = /\b(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}\b/;

export const endpoint = process.env.REACT_APP_API_ENDPOINT;

export const headers = async () => {
  const session = await Auth.currentSession();
  return {
    "Content-Type": "application/json",
    "Cache-Control": "public",
    Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
  };
};

export const fetchWithReject = (input: RequestInfo | URL, init?: RequestInit): Promise<Response> =>
  fetch(input, init).then(res => {
    if (!res.ok) {
      res.json().then(console.error);
      throw Error(`Request rejected with status ${res.status}`);
    }
    return res;
  });

export const stripPii = (text: string) =>
  text
    .replace(emailRegex, "{{EMAIL}}")
    .replace(DOB, "{{DOB}}")
    .replace(cc, "{{CARD}}")
    .replace(ph, "{{PHONE}}")
    .replace("{{DOB}}", "{{DATE}}");

export const select = <T,>(arr: T[], idxs: number[]) => idxs.map(i => arr[i]).filter(z => !!z);

const acronyms = ["sms", "nps", "csat", "dsat", "fcr", "id", "url", "api", "ui", "ux", "sf", "uri"];

const titleCase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const displayName = (
  str: string | undefined | null,
  displayNames?: { [name: string]: string }
) => {
  if (str === undefined || str === null) return "None";
  if (displayNames && str in displayNames) return displayNames[str];
  if (str.toString().trim() === "") return "N/A";

  return str
    .toString()
    .replaceAll("_", " ")
    .replaceAll(/(\w)-(\w)/g, "$1 $2")
    .replaceAll(/((?:(?:^|\s)[a-z]|[A-Z])[a-z0-9]+)/g, "$1 ")
    .trim()
    .replaceAll("s{2,}", " ")
    .split(" ")
    .map(word => {
      const w = word.toLowerCase();
      return acronyms.includes(w) ? w.toUpperCase() : titleCase(w);
    })
    .join(" ");
};

export const isDefined = <T,>(item: T | null | undefined): item is T => !!item;

export const sum = (a: number, b: number) => a + b;

export const truncate = (str: string, len: number, wrapInSpan = true) => {
  const shortened = str.substr(0, len - 1) + (str.length > len ? "..." : "");
  return wrapInSpan ? <span title={str}>{shortened}</span> : shortened;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSidebarSubMenu = (obj: any): obj is SidebarSubMenu => {
  // eslint-disable-next-line id-blacklist
  return obj.items !== undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSidebarItem = (obj: any): obj is SidebarItem => {
  // eslint-disable-next-line id-blacklist
  return obj.reportSet !== undefined;
};

export const buildShareUrl = (
  reportUrlHash: string,
  clusterId = "",
  superclusterId = "",
  megaclusterId = ""
) => {
  const cIdStr = clusterId ? `cid=${clusterId}` : undefined;
  const scIdStr = superclusterId ? `sid=${superclusterId}` : undefined;
  const mcIdStr = megaclusterId ? `mid=${megaclusterId}` : undefined;
  return `${window.location.origin}/dashboards/${reportUrlHash}?${[cIdStr, scIdStr, mcIdStr]
    .filter(isDefined)
    .join("&")}`;
};

export const formatPercent = (p: number, precision = 2) =>
  `${p < 0.0001 ? "< 0.01" : (p * 100).toFixed(precision)}%`;

const lineColors = [
  "#465fc3", // Original blue
  "#ff4e4e", // Original red
  "#43b139", // Original green
  "#faa263", // Original orange
  "#ffbf00", // Original yellow
  "#8e44ad", // Purple
  "#16a085", // Teal
  "#e67e22", // Dark orange
  "#3498db", // Light blue
  "#2ecc71", // Lime green
  "#e74c3c", // Bright red
  "#1abc9c", // Turquoise
  "#f39c12", // Amber
  "#9b59b6", // Lavender
  "#27ae60", // Emerald
];

export const tagColor = (tagId: string): string => {
  // Convert the tag ID to a number
  const numericId = tagId.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);

  // Use modulo to ensure the index is within the bounds of lineColors array
  const colorIndex = numericId % lineColors.length;

  // Return the selected color
  return lineColors[colorIndex];
};

export const getContrastColor = (hexColor: string) => {
  // Convert hex to RGB
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black or white based on luminance
  return luminance > 0.65 ? "#363F4A" : "#FBFBFB";
};
