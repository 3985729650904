import {
  Alert,
  Tooltip as AntTooltip,
  Card,
  Col,
  DatePicker,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Typography,
} from "antd";
import moment, { Moment } from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router";

import { isEmpty } from "lodash";
import { MetadataAnalysisWidget } from "../components/MetadataAnalysisWidget";
import { MultiFilter } from "../components/MultiFilter";
import "../components/ReportsViewer.less";
import { TaxonomyNode } from "../components/TaxonomyNode";
import { TimeseriesChart } from "../components/TimeseriesChart";
import { DashboardContext, useDashboardContext, useDashboardReducer } from "../hooks";
import { isAPIError } from "../indexTypes";
import {
  getTaxonomy,
  getTaxonomyNode,
  getTaxonomyOverviewMetadata,
  getView,
  getViewFilterOptions,
} from "../reportApi";
import {
  MetadataAnalysisOptions,
  TaxonomyNodeData,
  TaxonomyTimeseriesMetadata,
} from "../types/dashboardTypes";
import { ParsedExpression } from "../types/expressionsDslTypes";

const { Title } = Typography;

const GranularitySelector: React.FC<{
  granularity: string;
  onGranularityChange: (value: string) => void;
}> = ({ granularity, onGranularityChange }) => (
  <Space>
    <label htmlFor="granularity">Show by:</label>
    <Select id="granularity" defaultValue={granularity} onChange={onGranularityChange}>
      <Select.Option value="hour">Hour</Select.Option>
      <Select.Option value="day">Day</Select.Option>
      <Select.Option value="week">Week</Select.Option>
      <Select.Option value="month">Month</Select.Option>
    </Select>
  </Space>
);

const OverviewCard: React.FC<{
  taxonomyLoading: boolean;
  taxonomyTimeseriesMetadata?: TaxonomyTimeseriesMetadata;
  chartTitle: string;
  viewId: string;
  startDate: string;
  endDate: string;
  metadataAnalysisChoice: string;
  filters?: ParsedExpression;
}> = ({
  taxonomyLoading: loading,
  taxonomyTimeseriesMetadata,
  chartTitle,
  viewId,
  startDate,
  endDate,
  metadataAnalysisChoice,
  filters,
}) => (
  <Row key="reportOverviewCard">
    <Col span={24}>
      <Card className="reportOverviewCard rounded" style={{ marginBottom: 24 }}>
        <Row>
          <Col span={16} style={{ textAlign: "center" }}>
            <Spin spinning={loading || !taxonomyTimeseriesMetadata?.id}>
              <AntTooltip title={() => "Total Contacts in this Report"}>
                <Statistic value={taxonomyTimeseriesMetadata?.total} />
              </AntTooltip>
              <TimeseriesChart
                key={taxonomyTimeseriesMetadata?.id}
                metadata={taxonomyTimeseriesMetadata?.rawCounts}
                secondaryMetadata={taxonomyTimeseriesMetadata?.percentCounts}
                graphheight={300}
                xaxis
                yaxis
                grid
                animationDuration={150}
                preserve={"preserveEnd"}
                percent={false}
                xTickFormat={"MMM D"}
                legendText="Contacts"
                showTooltip
              />
            </Spin>
          </Col>
          <Col span={8}>
            <MetadataAnalysisWidget
              chartTitle={chartTitle}
              type="bar"
              viewId={viewId}
              startDate={startDate}
              endDate={endDate}
              metadataAnalysisChoice={metadataAnalysisChoice}
              filters={filters}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  </Row>
);

const OverviewCardContainer = () => {
  const { state } = useDashboardContext();
  return (
    <OverviewCard
      taxonomyLoading={state.taxonomyLoading}
      taxonomyTimeseriesMetadata={state.taxonomyTimeseriesMetadata}
      chartTitle={
        state.metadataAnalysisChoice &&
        state.view?.settings.metadataAnalysisOptions[state.metadataAnalysisChoice]
          ? state.view.settings.metadataAnalysisOptions[state.metadataAnalysisChoice].display_name
          : ""
      }
      viewId={state.view?.id || ""}
      startDate={state.startDate.format("YYYY-MM-DD")}
      endDate={state.endDate.format("YYYY-MM-DD")}
      metadataAnalysisChoice={state.metadataAnalysisChoice || ""}
      filters={state.filters}
    />
  );
};

const TaxonomyNodeList: React.FC<{
  taxonomyNodeData: TaxonomyNodeData[];
  granularity: string;
  startDate: string;
  endDate: string;
  taxonomyId: string;
  viewId: string;
  metadataAnalysisOptions: MetadataAnalysisOptions;
  metadataAnalysisChoice: string;
  filters?: ParsedExpression;
}> = ({
  taxonomyNodeData,
  granularity,
  startDate,
  endDate,
  taxonomyId,
  viewId,
  metadataAnalysisOptions,
  metadataAnalysisChoice,
  filters,
}) => {
  const sortedNodeData = [...taxonomyNodeData].sort((a, b) => b.total - a.total);
  return (
    <Row style={{ margin: "12px 0" }}>
      {sortedNodeData.map((nodeData: TaxonomyNodeData) => (
        <Col span={24} key={nodeData.id}>
          <TaxonomyNode
            taxonomyNodeData={nodeData}
            granularity={granularity}
            startDate={startDate}
            endDate={endDate}
            taxonomyId={taxonomyId}
            viewId={viewId}
            metadataAnalysisOptions={metadataAnalysisOptions}
            metadataAnalysisChoice={metadataAnalysisChoice}
            filters={filters}
          />
        </Col>
      ))}
    </Row>
  );
};

const DateRangeSelector: React.FC<{
  startDate: Moment;
  endDate: Moment;
  onDateChange: (dates: [Moment, Moment]) => void;
}> = ({ startDate, endDate, onDateChange }) => (
  <Space>
    <label htmlFor="datePicker">Report Range:</label>
    <DatePicker.RangePicker
      id="datePicker"
      onChange={dates => {
        if (dates && dates[0] && dates[1]) {
          onDateChange([dates[0], dates[1]]);
        }
      }}
      defaultValue={[startDate, endDate]}
      disabledDate={current => current > moment()}
      className="rounded"
    />
  </Space>
);

const MetadataAnalysisSelector: React.FC<{
  metadataAnalysisChoice: string;
  metadataAnalysisOptions: MetadataAnalysisOptions;
  onMetadataAnalysisChange: (value: string) => void;
}> = ({ metadataAnalysisChoice, metadataAnalysisOptions, onMetadataAnalysisChange }) => {
  return (
    <Space>
      <label htmlFor="metadataAnalysisSelector">Analyze by:</label>
      <Select
        value={metadataAnalysisChoice}
        defaultValue={metadataAnalysisChoice}
        onChange={onMetadataAnalysisChange}
        style={{ width: "150px" }}
      >
        {Object.entries(metadataAnalysisOptions).map(([fieldName, analysisConfigs]) => (
          <Select.Option key={fieldName} value={fieldName}>
            {analysisConfigs.display_name}
          </Select.Option>
        ))}
      </Select>
    </Space>
  );
};

const MetadataAnalysisSelectorContainer = () => {
  const { state, dispatch } = useDashboardContext();

  const handleMetadataAnalysisChange = (value: string) => {
    dispatch({ type: "SET_METADATA_ANALYSIS_CHOICE", payload: value });
  };

  return (
    <MetadataAnalysisSelector
      metadataAnalysisChoice={state.metadataAnalysisChoice ?? ""}
      metadataAnalysisOptions={state.view?.settings.metadataAnalysisOptions || {}}
      onMetadataAnalysisChange={handleMetadataAnalysisChange}
    />
  );
};

export const Dashboard: React.FC = () => {
  const { viewId } = useParams<{ viewId: string }>();
  const [state, dispatch] = useDashboardReducer();

  useEffect(() => {
    const controller = new AbortController();
    const fetchViewData = async () => {
      dispatch({ type: "FETCH_DATA" });
      if (!viewId) {
        dispatch({ type: "SET_ERROR", payload: "No view ID provided" });
        return;
      }
      try {
        const viewResp = await getView(viewId, controller.signal);
        if (isAPIError(viewResp)) {
          dispatch({ type: "SET_ERROR", payload: "Error fetching view" });
          return;
        }

        dispatch({ type: "SET_VIEW", payload: viewResp });
      } catch (error) {
        dispatch({ type: "SET_ERROR", payload: "Error fetching taxonomy" });
      } finally {
        dispatch({ type: "SET_VIEW_LOADING", payload: false });
      }
    };

    fetchViewData();
    return () => controller.abort();
  }, [viewId, dispatch]);

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      if (state.view?.id) {
        const filterOptionsResp = await getViewFilterOptions(
          state.view.id,
          state.startDate.format("YYYY-MM-DD"),
          state.endDate.format("YYYY-MM-DD"),
          controller.signal,
        );

        if (!isEmpty(filterOptionsResp)) {
          dispatch({ type: "SET_FILTER_OPTIONS", payload: filterOptionsResp });
        }
      }
    }

    fetchData();
    return () => controller.abort();
  }, [dispatch, state.view?.id, state.startDate, state.endDate]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      dispatch({ type: "SET_TAXONOMY_LOADING", payload: true });
      dispatch({ type: "SET_TAXONOMY_NODE_LIST_LOADING", payload: true });
      try {
        if (state.view?.id && state.view.taxonomyId) {
          const taxonomyId = state.view.taxonomyId;
          const startDate = state.startDate.format("YYYY-MM-DD");
          const endDate = state.endDate.format("YYYY-MM-DD");
          const taxonomyPromise = getTaxonomy(taxonomyId, controller.signal);
          const taxonomyTimeseriesPromise = getTaxonomyOverviewMetadata(
            taxonomyId,
            state.view.id,
            startDate,
            endDate,
            state.granularity,
            state.filters,
            controller.signal
          );
          const promises = [taxonomyPromise, taxonomyTimeseriesPromise];
          const [taxonomyResp, taxonomyTimeseriesResp] = await Promise.all(promises);

          if (isAPIError(taxonomyResp) || isAPIError(taxonomyTimeseriesResp)) {
            dispatch({ type: "SET_ERROR", payload: "Error fetching taxonomy" });
            return;
          }
          dispatch({ type: "SET_TAXONOMY", payload: taxonomyResp });
          dispatch({ type: "SET_TAXONOMY_TIMESERIES", payload: taxonomyTimeseriesResp });
          dispatch({ type: "SET_TAXONOMY_LOADING", payload: false });

          // Fetch TaxonomyNodeData for all root nodes
          const nodeDataPromises = taxonomyResp.rootNodeIds.map((nodeId: string) =>
            state.view?.id && getTaxonomyNode(
              taxonomyId,
              nodeId,
              state.view.id,
              {
                granularity: state.granularity,
                startDate: state.startDate.format("YYYY-MM-DD"),
                endDate: state.endDate.format("YYYY-MM-DD"),
                filters: state.filters,
              },
              controller.signal
            )
          );
          const nodeDataResults = await Promise.all(nodeDataPromises);
          dispatch({ type: "SET_TAXONOMY_NODE_DATA", payload: nodeDataResults });
        }
      } catch (error) {
        if (!controller.signal.aborted) {
          dispatch({ type: "SET_ERROR", payload: "Error fetching taxonomy" });
        }
      } finally {
        if (!controller.signal.aborted) {
          dispatch({ type: "SET_TAXONOMY_LOADING", payload: false });
          dispatch({ type: "SET_TAXONOMY_NODE_LIST_LOADING", payload: false });
        }
      }
    };

    fetchData();
    return () => controller.abort();
  }, [
    dispatch,
    state.view?.id,
    state.view?.taxonomyId,
    state.startDate,
    state.endDate,
    state.granularity,
    state.filters,
  ]);

  const DateRangeSelectorContainer: React.FC = () => {
    const handleDateChange = (dates: [Moment, Moment]) => {
      dispatch({
        type: "SET_DATES",
        payload: { startDate: dates[0], endDate: dates[1] },
      });
    };

    return (
      <DateRangeSelector
        startDate={state.startDate}
        endDate={state.endDate}
        onDateChange={handleDateChange}
      />
    );
  };

  return (
    <DashboardContext.Provider value={{ state, dispatch }}>
      <div>
        <Alert
          type="warning"
          banner
          showIcon
          message="Note – This page is in beta and not all features are implemented yet. See the Changelog for announcements!"
        />
        <div style={{ margin: "24px" }} id="reportsviewer">
          {state.error && <Alert type="error" message={state.error} className="rounded" showIcon />}
          <Row style={{ margin: "24px 0 0" }}>
            <Col span={24}>
              <Title level={3}>{state.view?.name || "Support Conversation Analysis"}</Title>
            </Col>
          </Row>
          <Row style={{ margin: "12px 0" }}>
            <Col span={20}>
              <Space size="small">
                <DateRangeSelectorContainer/>
                <GranularitySelector
                  granularity={state.granularity}
                  onGranularityChange={value =>
                    dispatch({ type: "SET_GRANULARITY", payload: value })
                  }
                />
                <MetadataAnalysisSelectorContainer />
              </Space>
            </Col>
          </Row>
          <Row style={{ margin: "12px 0" }}>
            <Col span={24}>
              {state.view && state.filterOptions && (
                <MultiFilter
                  viewFilters={state.view.settings.filters}
                  filterOptions={state.filterOptions}
                />
              )}
            </Col>
          </Row>
          <OverviewCardContainer />
          <Spin spinning={state.taxonomyNodeListLoading} tip="Loading Issues...">
            {state.view && state.taxonomy && state.metadataAnalysisChoice && <TaxonomyNodeList
              taxonomyNodeData={state.taxonomyNodeData}
              granularity={state.granularity}
              startDate={state.startDate.format("YYYY-MM-DD")}
              endDate={state.endDate.format("YYYY-MM-DD")}
              taxonomyId={state.taxonomy.id}
              viewId={state.view.id}
              metadataAnalysisOptions={state.view.settings.metadataAnalysisOptions}
              metadataAnalysisChoice={state.metadataAnalysisChoice}
              filters={state.filters}
            />}
          </Spin>
        </div>
      </div>
    </DashboardContext.Provider>
  );
};
