import { CommentOutlined, MessageOutlined } from "@ant-design/icons";
import { Button, Input, Radio, RadioChangeEvent, Table, Tag, Tooltip, Typography } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useCustomer } from "../hooks";
import { getTextSearchResults } from "../reportApi";
import { getContrastColor, tagColor } from "../utils";

const { Text, Title } = Typography;
const { Search } = Input;

interface TextRecord {
  text_id: string;
  conversation_id: string;
  timestamp: string;
  text: string;
  tags: { id: string; name: string }[];
}

type TextFilterValue = "all" | "filtered" | "tagged";

export const TextExplorerPage = () => {
  const { customer } = useCustomer();

  const [searchParams, setSearchParams] = useSearchParams();

  const [data, setData] = useState<TextRecord[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [searchLoading, setSearchLoading] = useState(false);
  const [textFilterOption, setTextFilterOption] = useState<TextFilterValue>("all");

  const handleFilterChange = (e: RadioChangeEvent) => {
    setTextFilterOption(e.target.value);
  };

  const columns = [
    {
      title: "Conv. ID",
      dataIndex: "conversation_id",
      key: "conversation_id",
      render: (conversationId: string) => (
        <Tooltip title="Click to copy" placement="topLeft">
          <Button
            icon={<CommentOutlined />}
            size="small"
            onClick={() => navigator.clipboard.writeText(conversationId)}
          ></Button>
        </Tooltip>
      ),
      width: "35px",
    },
    {
      title: "Text ID",
      dataIndex: "text_id",
      key: "text_id",
      render: (textId: string) => (
        <Tooltip title="Click to copy" placement="topLeft">
          <Button
            icon={<MessageOutlined />}
            size="small"
            onClick={() => navigator.clipboard.writeText(textId)}
          ></Button>
        </Tooltip>
      ),
      width: "35px",
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp: string) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
      },
      sorter: (a: TextRecord, b: TextRecord) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
      sorter: (a: TextRecord, b: TextRecord) => a.text.localeCompare(b.text),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags: { id: string; name: string }[]) => (
        <>
          {tags.map(tag => (
            <Tag
              key={tag.id}
              color={tagColor(tag.name)}
              style={{
                color: getContrastColor(tagColor(tag.name)),
                maxWidth: "230px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                borderRadius: "4px",
              }}
            >
              {tag.name}
            </Tag>
          ))}
        </>
      ),
    },
  ];

  const updateSearchParams = useCallback(
    (params: { texts?: string; query?: string }) => {
      const newSearchParams = new URLSearchParams(searchParams);
      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined) {
          newSearchParams.set(key, value.toString());
        }
      });
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleSearch = useCallback(() => {
    setSearchLoading(true);
    updateSearchParams({ texts: textFilterOption, query: searchQuery });
    getTextSearchResults(customer.id, textFilterOption, searchQuery)
      .then(setData)
      .finally(() => setSearchLoading(false));
  }, [customer.id, textFilterOption, searchQuery, updateSearchParams]);

  const debouncedHandleSearch = useMemo(() => debounce(() => handleSearch(), 400), [handleSearch]);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    setTextFilterOption(
      (params.get("texts") === "tagged"
        ? "tagged"
        : params.get("texts") === "filtered"
        ? "filtered"
        : "all") as TextFilterValue
    );
    setSearchQuery(params.get("query") || "");
  }, [searchParams]);

  useEffect(() => {
    debouncedHandleSearch();
  }, [debouncedHandleSearch]);

  useEffect(() => {
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [debouncedHandleSearch]);

  return (
    <div style={{ margin: "48px" }}>
      <Title level={3} style={{ marginBottom: "24px" }}>
        Text Explorer
      </Title>
      <div style={{ marginBottom: "12px", display: "flex", alignItems: "center" }}>
        <Text strong style={{ marginRight: "10px" }}>
          Texts to Include:
        </Text>
        <Radio.Group onChange={handleFilterChange} value={textFilterOption} size="small">
          <Tooltip title="Include all texts">
            <Radio.Button value="all">All</Radio.Button>
          </Tooltip>
          <Tooltip title="Include only texts that pass a simple filter, excluding things like 'ok' or 'um'">
            <Radio.Button value="filtered">Filtered</Radio.Button>
          </Tooltip>
          <Tooltip title="Include only texts that have pass the filters AND have at least 1 Tag">
            <Radio.Button value="tagged">Tagged</Radio.Button>
          </Tooltip>
        </Radio.Group>
      </div>

      <Search
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        placeholder="Enter your search query"
        enterButton="Search"
        size="large"
        style={{ width: "100%", marginBottom: "24px" }}
        onSearch={handleSearch}
      />
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: 100,
          showSizeChanger: false,
          showQuickJumper: false,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        size="small"
        loading={searchLoading}
      />
    </div>
  );
};
