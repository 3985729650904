import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Tooltip as AntTooltip, Button, Col, Divider, Modal, Row, Space, Spin } from "antd";
import Linkify from "linkify-react";
import { Conversation, ConversationMetadata } from "../indexTypes";
import { getConversation } from "../reportApi";
import { stripPii } from "../utils";
import { CopyButton } from "./CopyButton";

export const ConversationModal = ({
    visible,
    setVisible,
    modalType,
    conversationMetadata,
  }: {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    modalType?: string; // TODO: Use to add stars for reviews or remove
    conversationMetadata: ConversationMetadata;
  }) => {
    const [loading, setLoading] = useState(false);
    const [conversation, setConversation] = useState<Conversation | undefined>(undefined);
    const { optional_metadata = {} } = conversationMetadata;

    useEffect(() => {
      setLoading(true);
      setConversation(undefined);
      const fetchConversation = async () => {
        const conversation = await getConversation(conversationMetadata.uuid);
        setConversation(conversation);
        setLoading(false);
      }
      fetchConversation();
    }, [conversationMetadata]);

    return (
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={[
          <Button key="close" type="primary" onClick={() => setVisible(false)}>
            Close
          </Button>,
        ]}
        bodyStyle={{ height: modalType === "review" ? "40vh" : "80vh", overflowY: "scroll" }}
        width={1040}
        zIndex={9999}
        className="modal"
      >
        <Row style={{ height: "100%" }}>
          <Col span="7" style={{ overflow: "auto", maxHeight: "100%", wordBreak: "break-all" }}>
            <Space direction="vertical">
              {conversation && <div key="conversationKey" className="selectable">
                <b>Conversation Key:</b>
                <br />
                <Space>
                  {conversation.key}
                  <CopyButton text={conversation.key} />
                </Space>
              </div>}
              {Object.entries(optional_metadata)
                .filter(([, v]) => v !== "unknown") // Remove this if we stop filling NA with "unknown"
                .map(([k, v]) => (
                  <div key={k} className="selectable">
                    <AntTooltip title={k} zIndex={10001}>
                      <b>{k}:</b>
                    </AntTooltip>
                    <br />
                    <Linkify options={{ target: "_blank" }}>
                      {JSON.stringify(v).replace(/^"?(.*?)"?$/, "$1")}
                    </Linkify>
                  </div>
                ))}
            </Space>
          </Col>
          <Divider type="vertical" style={{ height: "100%" }} />
            <Col span="16" style={{ overflow: "auto", maxHeight: "100%", padding: "0 15px" }}>
              <Spin spinning={loading} style={{width: "100%", paddingTop: "20px"}}>
              {conversation && conversation.messages
                .filter(msg => msg.message.length > 0)
                .map((msg, i) => {
                  const userType =
                    msg.user_type == "customer"
                      ? "customer"
                      : msg.is_agent
                      ? "agent"
                      : msg.user_type == "system" && msg.message.length > 60
                      ? "agent"
                      : msg.user_type == "system"
                      ? "system"
                      : "agent";
                  // TODO: Add stars for reviews
                  const bubble = (
                    <div
                      id={`modal-msg-${i}`}
                      className={`message ${userType} selectable`}
                    >
                      {stripPii(msg.message)}
                    </div>
                  );
                  return (
                    <div key={`modal-msg-${i}`} className="message-container">
                        {bubble}
                    </div>
                  );
                })}
              </Spin>
            </Col>
        </Row>
      </Modal>
    );
  };
