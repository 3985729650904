import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { Tooltip as AntTooltip, Button } from "antd";
import React, { useEffect, useState } from "react";

const graphGreen = "#43B139";
const grey ="#d0d0d0";

export const CopyButton = ({ text, tooltipTitle }: { text: string; tooltipTitle?: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const timer = setInterval(() => setIsCopied(false), 1200);
      return () => clearInterval(timer);
    }
  }, [isCopied]);

  const btn = (
    <Button
      style={{ color: isCopied ? graphGreen : grey }}
      icon={isCopied ? <CheckOutlined /> : <CopyOutlined />}
      type="text"
      onClick={e => {
        setIsCopied(true);
        navigator.clipboard.writeText(text);
        e.stopPropagation();
      }}
    />
  );
  if (tooltipTitle) {
    return <AntTooltip title={tooltipTitle}>{btn}</AntTooltip>;
  } else {
    return btn;
  }
};
