import React, { useState } from "react";

import { SelectOutlined } from "@ant-design/icons";
import { Tooltip as AntTooltip, Space, Table, Typography } from "antd";
import moment from "moment";
import { useDashboardContext } from "../hooks";
import { ConversationMetadata } from "../indexTypes";
import { isDefined } from "../utils";
import { ConversationModal } from "./ConversationModal";

const CRM_URL_CONVERSATION_ID_PLACEHOLDER = "<conversation_id>";
const renderCrmUrl = (crmUrlTemplate: string, conversation: ConversationMetadata) => {
  let crmUrl = crmUrlTemplate.replace(
    CRM_URL_CONVERSATION_ID_PLACEHOLDER,
    conversation.conversation_key
  );
  [...crmUrl.matchAll(/<.*?>/g)]
    .map(match => match[0])
    .forEach(match => {
      const name = match.slice(1, -1);
      if (
        conversation.optional_metadata &&
        name in conversation.optional_metadata &&
        isDefined(crmUrl)
      ) {
        crmUrl = crmUrl.replace(match, String(conversation.optional_metadata[name]));
      }
    });
  return crmUrl;
};

export const ExampleConversationsTable: React.FC<{ conversations: ConversationMetadata[] }> = ({
  conversations,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [conversationMetadata, setConversationMetadata] = useState<
    ConversationMetadata | undefined
  >(undefined);
  const dashboardContext = useDashboardContext();
  const crmUrlTemplate = dashboardContext.state.view?.settings.crmUrlTemplate;

  const exampleConversationsMetadataFields = conversations.reduce((acc, curr) => {
    if (curr.optional_metadata) {
      Object.keys(curr.optional_metadata).forEach(f => acc.add(f));
    }
    return acc;
  }, new Set<string>());

  return (
    <div>
      {conversationMetadata && (
        <ConversationModal
          visible={modalVisible}
          setVisible={setModalVisible}
          conversationMetadata={conversationMetadata}
        />
      )}
      <Table
        dataSource={conversations}
        rowKey={conv => conv.conversation_id}
        locale={{ emptyText: "No example conversations found" }}
        pagination={false}
        size="middle"
        scroll={{ x: true }}
        className="rounded"
        onRow={row => ({
          onClick: () => {
            setConversationMetadata(row);
            setModalVisible(true);
          },
        })}
      >
        <Table.Column
          title="Conversation Key"
          dataIndex="conversation_key"
          key="conversation_key"
          render={(value, record: ConversationMetadata) =>
            crmUrlTemplate ? (
              <div style={{ width: "125px" }}>
                <AntTooltip title={"View in CRM"}>
                  <Typography.Link href={renderCrmUrl(crmUrlTemplate, record)}>
                    <Space>
                      <span>{value}</span>
                      <SelectOutlined rotate={90} />
                    </Space>
                  </Typography.Link>
                </AntTooltip>
              </div>
            ) : (
              <span>{value}</span>
            )
          }
        />
        <Table.Column title="Dataset Id" dataIndex="dataset_id" key="dataset_id" />
        <Table.Column
          title="Timestamp"
          dataIndex="timestamp"
          key="timestamp"
          render={t => moment(t).format("MM-DD-YYYY HH:mm:ss")}
        />
        {Array.from(exampleConversationsMetadataFields).map(f => (
          <Table.Column
            title={f}
            dataIndex={`optional_metadata`}
            key={`metadata-"${f}"`}
            render={m => m[f]}
          />
        ))}
      </Table>
    </div>
  );
};
