import { Col, Collapse, Row, Spin } from "antd";
import React, { useCallback, useEffect, useReducer } from "react";

import { GetTaxonomyNodeExampleConversationsResponse } from "../indexTypes";
import { getTaxonomyNode, getTaxonomyNodeExampleConversations } from "../reportApi";
import { MetadataAnalysisOptions, TaxonomyNodeData } from "../types/dashboardTypes";
import { ParsedExpression } from "../types/expressionsDslTypes";
import { ExampleConversationsTable } from "./ExampleConversationsTable";
import { MetadataAnalysisWidget } from "./MetadataAnalysisWidget";
import { TaxonomyNodeHeader } from "./TaxonomyNodeHeader";
import { TimeseriesChart } from "./TimeseriesChart";

interface TaxonomyNodeProps {
  taxonomyNodeData: TaxonomyNodeData;
  exampleConversations?: GetTaxonomyNodeExampleConversationsResponse;
  parentId?: string;
  scale?: number;
  granularity: string;
  taxonomyId: string;
  startDate: string;
  endDate: string;
  viewId: string;
  metadataAnalysisOptions: MetadataAnalysisOptions;
  metadataAnalysisChoice: string;
  filters?: ParsedExpression;
}

interface TaxonomyNodeState {
  isLoadingChildrenData: boolean;
  childrenData: TaxonomyNodeData[];
  isLoadingExampleConversations: boolean;
  exampleConversations?: GetTaxonomyNodeExampleConversationsResponse;
}

const initialState: TaxonomyNodeState = {
  isLoadingChildrenData: false,
  childrenData: [],
  isLoadingExampleConversations: false,
  exampleConversations: undefined,
};

type TaxonomyNodeAction =
  | { type: "SET_LOADING_CHILDREN_DATA" }
  | { type: "SET_CHILDREN_DATA"; payload: TaxonomyNodeData[] }
  | { type: "SET_LOADING_EXAMPLE_CONVERSATIONS" }
  | { type: "SET_EXAMPLE_CONVERSATIONS"; payload: GetTaxonomyNodeExampleConversationsResponse };

// TODO: Since we're sending a fanout of individual requests for each child node, this could be optimized by not making ALL nodes wait
// for every other node's promise to resolve. Currently, load time is limited by the *slowest* request. At least there's a separation
// between loading the timeseries and example conversations so rendering the timeseries graph doesn't have to also wait on all of the
// example conversations to load, but still we could probably make a big dent in UX by getting more serious about this.
function taxonomyNodeReducer(
  state: TaxonomyNodeState,
  action: TaxonomyNodeAction
): TaxonomyNodeState {
  switch (action.type) {
    case "SET_LOADING_CHILDREN_DATA":
      return { ...state, isLoadingChildrenData: true, childrenData: [] };
    case "SET_CHILDREN_DATA":
      return { ...state, childrenData: action.payload, isLoadingChildrenData: false };
    case "SET_LOADING_EXAMPLE_CONVERSATIONS":
      return { ...state, isLoadingExampleConversations: true, exampleConversations: undefined };
    case "SET_EXAMPLE_CONVERSATIONS":
      return {
        ...state,
        isLoadingExampleConversations: false,
        exampleConversations: action.payload,
      };
    default:
      return state;
  }
}

export const TaxonomyNode: React.FC<TaxonomyNodeProps> = ({
  taxonomyNodeData,
  parentId = undefined,
  scale = undefined,
  granularity,
  taxonomyId,
  startDate,
  endDate,
  viewId,
  metadataAnalysisOptions,
  metadataAnalysisChoice,
  filters,
}: TaxonomyNodeProps) => {
  const [state, dispatch] = useReducer(taxonomyNodeReducer, initialState);
  const [isActive, setIsActive] = React.useState<boolean>(false);

  const { id, name, childIds, total, percent, mean, rawCounts, percentCounts } = taxonomyNodeData;

  const isLeafNode = childIds.length === 0;

  const fetchChildNodes = useCallback(async (abortController?: AbortController) => {
    const fetchChildrenData = async () => {
      dispatch({ type: "SET_LOADING_CHILDREN_DATA" });
      try {
        const childrenPromises = childIds.map((childId) => {
          const context = { granularity, startDate, endDate, filters};
          return getTaxonomyNode(
            taxonomyId,
            childId,
            viewId,
            context,
            abortController?.signal,
          )
        });

        const childrenData = await Promise.all(childrenPromises);
        const childrenDataSorted = childrenData.sort((a, b) => b.total - a.total);
        dispatch({ type: "SET_CHILDREN_DATA", payload: childrenDataSorted });
      } catch (error) {
        if (!abortController || !abortController.signal.aborted) {
          console.error("Error fetching child nodes:", error);
        }
      }
    };
    const fetchExampleConversations = async () => {
      dispatch({ type: "SET_LOADING_EXAMPLE_CONVERSATIONS" });
      try {
        const context = {
          startDate,
          endDate,
          viewId,
          optionalFiltersExpression: filters,
          // TODO: Determine how and when we want to render metadata.
          includeMetadata: true,
          limit: 10,
        }
        const exampleConversations = await getTaxonomyNodeExampleConversations(
          taxonomyId,
          id,
          context,
          abortController?.signal,
        );

        dispatch({ type: "SET_EXAMPLE_CONVERSATIONS", payload: exampleConversations });
      } catch (error) {
        if (!abortController || !abortController.signal.aborted) {
          console.error("Error fetching node example conversations:", error);
        }
      }
    };

    const promises = [fetchChildrenData()];
    if (isLeafNode) {
      promises.push(fetchExampleConversations());
    }
    await Promise.all(promises);
  }, [childIds, taxonomyId, viewId, granularity, startDate, endDate, filters, isLeafNode, id]);

  useEffect(() => {
    if (isActive) {
      const controller = new AbortController();
      fetchChildNodes(controller);
      return () => controller.abort();
    }
  }, [isActive, filters, fetchChildNodes]);

  const maxValue = percentCounts.data.reduce((acc, curr) => {
    return curr.value > acc ? curr.value : acc;
  }, 0);
  const chartScale = scale || maxValue;

  return (
    <div>
      <Collapse
        bordered={false}
        destroyInactivePanel
        onChange={activeKey => {
          if (activeKey && Array.isArray(activeKey) ? activeKey.includes(id) : activeKey === id) {
            setIsActive(true);
          } else {
            setIsActive(false);
          }
        }}
      >
        <Collapse.Panel
          key={id}
          header={
            <TaxonomyNodeHeader
              taxonomyNodeId={id}
              name={name}
              data={percentCounts}
              total={total}
              percent={percent}
              mean={mean}
              granularity={granularity}
              scale={chartScale}
              parentId={parentId}
              nodeChildren={childIds}
            />
          }
          className={childIds.length > 0 ? "superissue" : "subissue"}
        >
          <Collapse bordered={false} destroyInactivePanel>
            <Row className="metadata" style={{ padding: "24px", marginLeft: "0" }}>
              <Row>
                <Col span={16}>
                  <TimeseriesChart
                    metadata={percentCounts}
                    secondaryMetadata={rawCounts}
                    graphheight={320}
                    xaxis
                    yaxis
                    grid
                    animationDuration={150}
                    preserve={"preserveEnd"}
                    xTickFormat={"MMM D"}
                    showTooltip
                  />
                </Col>
                <Col span={8}>
                  <MetadataAnalysisWidget
                    chartTitle={metadataAnalysisOptions[metadataAnalysisChoice].display_name}
                    type="bar"
                    viewId={viewId}
                    startDate={startDate}
                    endDate={endDate}
                    metadataAnalysisChoice={metadataAnalysisChoice}
                    taxonomyNodeId={id}
                    filters={filters}
                  />
                </Col>
              </Row>
              {isLeafNode && (
                <Spin
                  spinning={state.isLoadingExampleConversations}
                  tip="Loading examples..."
                  style={{ minHeight: "80px", alignSelf: "center" }}
                >
                  {state.exampleConversations && <ExampleConversationsTable conversations={state.exampleConversations?.example_conversations} />}
                </Spin>
              )}
            </Row>
            <div style={{ minHeight: "80px" }}>
              <Spin
                spinning={state.isLoadingChildrenData}
                tip="Loading issues..."
                style={{ minHeight: "80px" }}
              >
                {state.childrenData.map(childData => (
                  <TaxonomyNode
                    key={childData.id}
                    taxonomyNodeData={childData}
                    viewId={viewId}
                    parentId={id}
                    scale={chartScale}
                    granularity={granularity}
                    taxonomyId={taxonomyId}
                    startDate={startDate}
                    endDate={endDate}
                    metadataAnalysisOptions={metadataAnalysisOptions}
                    metadataAnalysisChoice={metadataAnalysisChoice}
                    filters={filters}
                  />
                ))}
              </Spin>
            </div>
          </Collapse>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};
