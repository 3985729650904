import { Card, List, Tag, Typography } from "antd";
import React from "react";

const { Title } = Typography;

const ChangeLogPage: React.FC = () => {
  return (
    <div style={{ margin: "24px 64px" }}>
      <Title level={1} style={{ textAlign: "center" }}>
        Changelog
      </Title>
      <List
        grid={{ gutter: 16, column: 1 }}
        dataSource={[
          {
            title: "🎉 85% Accuracy",
            date: "2024-08-30",
            message:
              "We'll have 85%+ overall test case accuracy on V2, with content backfilled and in the dashboard views",
          },
          {
            title: "Core V1 Feature Parity",
            date: "2024-08-29",
            message: "V2 Dashboards now support Analyze By and Examples",
          },
          {
            title: "Improved Tags and Tag Accuracy Page",
            date: "2024-08-27",
            message:
              "We've updated all the V2 Tags to have more appropriate and descriptive names. As well, we've updated the Tag Accuracy page to show a 'tag' bubble for each Tag, as well as a description of what text being in that tag means. These descriptions also inform our models via GenAI and LLMs to help automatically improve training data and tag accuracy.",
          },
          {
            title: "Multi-Filtering on V2 Dashboards",
            date: "2024-08-27",
            message:
              "V2 Dashboards now support the ability to filter by multiple metadata at the same time! Select any field, and any number of values to filter the dashboard to whatever slice you need.",
          },
          {
            title: "Launching V2 Accuracy for Tags",
            date: "2024-08-16",
            message:
              "This milestone represents Tags all migrated to the V2 backend system, some of which already with increaed accuracy.",
          },
          {
            title: "Ongoing Data Classification in V2",
            date: "2024-08-14",
            message:
              "We're now classifying all incoming data against our V2 Tag models. So, the graphs in the V2 dashboard will stay up to date as new data comes in every day!",
          },
          {
            title: "Improved Tag Accuracy",
            date: "2024-08-09",
            message:
              "We've launched the first wave of our updated V2 classifiers, resulting in a 10 point accuracy boost and a 75% Overall Accuracy!",
          },
          {
            title: "Per-Channel Views",
            date: "2024-08-07",
            message:
              "We've added the ability to view your dashboard data on a per-channel basis in V2. Find them in the sidebar! This is also a nice precursor to user customizable and savable views, coming later this fall.",
          },
          {
            title: "Now Accepting: User Feedback on Tags!",
            date: "2024-08-02",
            message:
              "Now you can affect Tag quality by submitting feedback with the click of a button. User feedback gets recorded as a new Test Case on the Tag, which then updates its Accuracy Score and goes towards training the next version of the AI model. By creating new test cases – either manually or surfaced from Spiral’s AI – you improve the quality of both the Accuracy Score and the Tag classifications, which will roll up into an improved dashboard experience!",
          },
          {
            title: "Introducing the Tags page and Accuracy Scores",
            date: "2024-07-31",
            message:
              "We’re launching the new ‘Tags’ page! This lets you see for the first time the full list of Spiral Tags that power our issue classifications on the dashboard. Within each Tag, you can view the ‘test cases’ for each Tag. Spiral then calculates an Accuracy Score for each tag based on the percent of test cases that Spiral’s AI is currently classifying correctly. As the AI improves, you’ll see these scores go up!",
          },
          {
            title: "Data in V2 Dashboard",
            date: "2024-07-25",
            message:
              "We’ve ported the classification data from V1 into the V2 Dashboard, so it now shows data parity for classifications. This serves as a baseline for the current classification quality in V2 so we can measure the upcoming (big) improvements!\n\n" +
              "From here, we will be able to start bringing Turo data onto the V2 system in ernest by using our new data labeling and classification systems to improve accuracy.",
          },
          {
            title: "Launch: V2 Dashboard (Alpha) and Date Picker",
            date: "2024-07-24",
            message:
              "We're launching the first preview of our V2 Dashboard! It's not yet at full parity with the current V1 offerings, but it's critical for presenting the results from our new V2 Classification System.\n\n" +
              "This version of the dashboard will load significantly faster, as well as enable us to add multi-filtering, saved views, accuracy reports, and many of the other upcoming features!\n\n" +
              "Included in this version is a long-requested feature: the Date Picker to choose your own report windows!",
          },
          {
            title: "Launch: Okta Integration",
            date: "2024-06-14",
            message:
              "Spiral now supports Okta integration! You can now sign in with your Okta account. On the login page, click the 'Sign in with Okta' button to get started. If you have any questions or issues, please contact support!",
          },
        ]}
        renderItem={item => (
          <List.Item>
            <Card
              title={item.title}
              extra={
                <div>
                  { // @ts-expect-error: 'upcoming' is not in the type
                    item.upcoming && (
                    <Tag color="blue" style={{ marginLeft: "10px", borderRadius: "20px" }}>
                      Upcoming
                    </Tag>
                  )}
                  {item.date}
                </div>
              }
              size="small"
              style={{ maxWidth: "800px", margin: "0 auto" }}
              headStyle={{ fontSize: 18 }}
              bodyStyle={{ fontSize: 14 }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: item.message.replace(/\n/g, "<br />"),
                }}
              />
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ChangeLogPage;
