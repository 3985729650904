import {
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  RightOutlined,
  SaveOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useCustomer } from "../hooks";
import { isAPIError } from "../indexTypes";
import {
  createValidationSetCase,
  deleteValidationSetCase,
  getTagValidationSetCases,
  getTags,
  updateValidationSetCase,
} from "../reportApi";

import { ColumnType } from "antd/lib/table";
import { getContrastColor, sum, tagColor } from "../utils";
import "./TagAccuracyPage.less";

const { Title } = Typography;

interface BaseTagData {
  id: string;
  name: string;
  description: string;
  hypothesis: string;
  accuracy_updated_at?: string;
}

interface TagData extends BaseTagData {
  accuracy: number | null;
  correct: number;
  total: number;
}

interface TagDataWithAccuracy extends BaseTagData {
  accuracy: number;
}

interface ValidationSetCase {
  id: string;
  text: string;
  tag_id: string;
  set_id: string;
  expected_result: boolean;
  actual_result: boolean | null;
}

export const TagAccuracyPage: React.FC = () => {
  const [form] = Form.useForm();

  const { customer } = useCustomer();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingValidationSetCases, setIsLoadingValidationSetCases] = useState(true);
  const [error, setError] = useState<string>();
  const [filteredTagData, setFilteredTagData] = useState<TagData[]>();
  const [tagData, setTagData] = useState<TagData[]>([]);
  const [overallAccuracy, setOverallAccuracy] = useState<number>(0.0);
  const [updatedAt, setUpdatedAt] = useState<Date>();
  const [searchInput, setSearchInput] = useState("");
  const [activeTag, setActiveTag] = useState<TagData>();
  const [validationSetCases, setValidationSetCases] = useState<ValidationSetCase[]>([]);

  // We use a placeholder ID for the test case to add, so we can differentiate it from the other test cases
  // in validationSetCases
  const [caseIdToAdd, setCaseIdToAdd] = useState<"case_to_add">();
  const [caseIdToEdit, setCaseIdToEdit] = useState<string>();

  const saveTestCaseEdits = async () => {
    if (!caseIdToEdit) {
      return;
    }
    setIsLoadingValidationSetCases(true);
    try {
      const newSetCase = (await form.validateFields()) as ValidationSetCase;
      const setCaseCopies = [...validationSetCases];
      const caseIndex = setCaseCopies.findIndex(setCase => setCase.id === caseIdToEdit);
      if (caseIndex > -1) {
        const existing = setCaseCopies[caseIndex];
        const resp = await updateValidationSetCase(
          customer.id,
          existing.set_id,
          existing.tag_id,
          caseIdToEdit,
          newSetCase.text,
          newSetCase.expected_result
        );
        if (isAPIError(resp)) {
          console.error("Could not update validation set case");
          return;
        }
        // Update the UI with the saved data once we've successfully updated the test case
        setCaseCopies[caseIndex] = { ...(resp as ValidationSetCase) };
        setValidationSetCases(setCaseCopies);
      }
    } catch (error) {
      console.error("Error saving test case edits ", error);
      setError("Error saving test case edits");
    } finally {
      handleEditAndCancelEdit(undefined);
      setIsLoadingValidationSetCases(false);
    }
  };

  const saveNewTestCase = async () => {
    if (!caseIdToAdd) {
      return;
    }
    setIsLoadingValidationSetCases(true);
    try {
      const newSetCase = (await form.validateFields()) as ValidationSetCase;
      const presetDummyCase = validationSetCases.find(setCase => setCase.id === caseIdToAdd);
      if (!presetDummyCase) {
        console.error("Could not find dummy case to replace");
        return;
      }
      const resp = await createValidationSetCase(
        customer.id,
        presetDummyCase.set_id,
        presetDummyCase.tag_id,
        newSetCase.text,
        newSetCase.expected_result
      );
      if (isAPIError(resp)) {
        console.error("Could not add new validation set case");
        return;
      }
      // Update the UI with the saved data once we've successfully added the test case
      setValidationSetCases([
        resp as ValidationSetCase,
        ...validationSetCases.filter(setCase => setCase.id !== caseIdToAdd),
      ]);
    } catch (error) {
      console.error("Error saving new test case ", error);
      setError("Error saving new test case");
    } finally {
      form.resetFields();
      setCaseIdToAdd(undefined);
      // handleAddAndCancelAdd(); // Clear the test case to add and the form
      setIsLoadingValidationSetCases(false);
    }
  };

  const deleteTestCase = async (record: ValidationSetCase) => {
    setIsLoadingValidationSetCases(true);
    try {
      const resp = await deleteValidationSetCase(
        customer.id,
        record.set_id,
        record.tag_id,
        record.id
      );
      if (isAPIError(resp)) {
        console.error("Could not delete validation set case");
        return;
      }
      // Update the UI with the deleted test case removed
      setValidationSetCases([...validationSetCases.filter(setCase => setCase.id !== record.id)]);
    } catch (error) {
      console.error("Error deleting test case ", error);
      setError("Error deleting test case");
    } finally {
      setIsLoadingValidationSetCases(false);
    }
  };

  const handleEditAndCancelEdit = (record: ValidationSetCase | undefined) => {
    if (record) {
      // Edit a test case
      form.setFieldsValue({ ...record });
      setCaseIdToEdit(record.id);
    } else {
      // Cancel editing a test case
      form.setFieldsValue({});
      setCaseIdToEdit(undefined);
    }
  };

  const handleAddAndCancelAdd = () => {
    if (caseIdToAdd) {
      // Cancel adding a test case
      form.resetFields();
      setValidationSetCases([...validationSetCases.filter(setCase => setCase.id !== caseIdToAdd)]);
      setCaseIdToAdd(undefined);
    } else {
      // Start us off with an empty record to put on the top row of the table
      const dummyCaseRecord = {
        id: "case_to_add",
        text: "",
        expected_result: true,
        set_id: validationSetCases[0].set_id,
        tag_id: validationSetCases[0].tag_id,
      } as ValidationSetCase;
      form.setFieldsValue({ ...dummyCaseRecord });
      setCaseIdToAdd("case_to_add");
      setValidationSetCases([...validationSetCases, dummyCaseRecord]);
    }
  };

  const renderTestCaseControls = (_: string, record: ValidationSetCase) => {
    let button1Tooltip = "";
    let button2Tooltip = "";
    let button1Icon = <EditOutlined />;
    let button2Icon = <DeleteOutlined />;
    let buttonsDisabled = false;
    let button1OnClick: () => void = () => {};
    let button2OnClick: () => void = () => {};
    let button2Confirm = false;

    if (!caseIdToEdit && !caseIdToAdd) {
      // We're not adding or editing a test case
      button1Tooltip = "Edit Test Case";
      button1OnClick = () => handleEditAndCancelEdit(record);
      button2OnClick = () => deleteTestCase(record);
      button2Confirm = true;
    } else if (caseIdToEdit) {
      if (caseIdToEdit === record.id) {
        // We're editing this test case
        button1Tooltip = "Save Changes";
        button2Tooltip = "Cancel";
        button1Icon = <SaveOutlined />;
        button2Icon = <CloseOutlined />;
        button1OnClick = saveTestCaseEdits;
        button2OnClick = () => handleEditAndCancelEdit(undefined);
      } else {
        // We're editing a different test case
        buttonsDisabled = true;
      }
    } else if (caseIdToAdd) {
      if (caseIdToAdd === record.id) {
        // We're adding a new test case
        button1Tooltip = "Save New Test Case";
        button2Tooltip = "Cancel";
        button1Icon = <SaveOutlined />;
        button2Icon = <CloseOutlined />;
        button1OnClick = saveNewTestCase;
        button2OnClick = handleAddAndCancelAdd;
      } else {
        // We're adding a new test case, but this isn't the dummy row
        buttonsDisabled = true;
      }
    }

    return (
      <Space size="middle">
        <Tooltip title={button1Tooltip}>
          <Button
            type="primary"
            icon={button1Icon}
            disabled={buttonsDisabled}
            onClick={button1OnClick}
          />
        </Tooltip>
        <Tooltip title={button2Tooltip}>
          {button2Confirm ? (
            <Popconfirm
              title="Are you sure you want to delete this test case?"
              onConfirm={button2OnClick}
            >
              <Button type="primary" icon={button2Icon} disabled={buttonsDisabled} />
            </Popconfirm>
          ) : (
            <Button
              type="primary"
              icon={button2Icon}
              disabled={buttonsDisabled}
              onClick={button2OnClick}
            />
          )}
        </Tooltip>
      </Space>
    );
  };

  const renderTestCaseTextColumn = (_: string, record: ValidationSetCase) => {
    if (caseIdToEdit === record.id || caseIdToAdd === record.id) {
      return (
        <Form.Item name="text" key="text" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      );
    } else {
      return record.text;
    }
  };

  const renderTestCaseResultColumn = (_: boolean, record: ValidationSetCase) => {
    if (caseIdToEdit === record.id || caseIdToAdd === record.id) {
      return (
        <Form.Item name="expected_result" rules={[{ required: true }]}>
          <Select>
            <Select.Option value={true}>Yes</Select.Option>
            <Select.Option value={false}>No</Select.Option>
          </Select>
        </Form.Item>
      );
    } else {
      return record.expected_result ? "Yes" : "No";
    }
  };

  const columns: ColumnType<ValidationSetCase>[] = [
    {
      title: "Test Case Text",
      dataIndex: "text",
      key: "text",
      render: renderTestCaseTextColumn,
      ellipsis: true,
    },
    {
      title: (
        <Space size={0}>
          <Tooltip title="Whether the tag should be applied for the given text">
            Expected Result
          </Tooltip>
        </Space>
      ),
      dataIndex: "expected_result",
      key: "expected_result",
      render: renderTestCaseResultColumn,
      sorter: (a: ValidationSetCase, b: ValidationSetCase) => {
        return a.expected_result === b.expected_result ? 0 : a.expected_result ? 1 : -1;
      },
      align: "center",
      // filters: [
      //   { text: "Yes", value: true },
      //   { text: "No", value: false },
      // ],
      // onFilter: (value: boolean | Key, record: ValidationSetCase) => {
      //   return record.expected_result === value;
      // },
      width: 140,
    },
    {
      title: "Actual Result",
      dataIndex: "actual_result",
      key: "actual_result",
      render: value => (value === null ? "-" : value ? "Yes" : "No"),
      align: "center",
      width: 140,
    },
    {
      title: "Correct?",
      dataIndex: "",
      key: "correct",
      render: (_, record) =>
        record.actual_result === null
          ? "-"
          : record.expected_result === record.actual_result
          ? "✅"
          : "🚫",
      align: "center",
      width: 80,
    },
    {
      title: "",
      dataIndex: "controls",
      key: "controls",
      render: renderTestCaseControls,
      align: "center",
      width: 200,
    },
  ];

  const ValidationSetCaseTable: React.FC = () => {
    return (
      <Spin spinning={isLoadingValidationSetCases}>
        <Form form={form} component={false}>
          <Row style={{ margin: "12px" }}>
            <Col span={24}>
              <Table
                bordered={false}
                dataSource={validationSetCases}
                columns={columns}
                rowKey={record => record.id}
                pagination={false}
                locale={{ emptyText: "No test cases found" }}
                size="small"
                style={{ margin: "8px" }}
                showSorterTooltip={false}
              />
            </Col>
          </Row>
          <Row style={{ margin: "24px" }} justify="start">
            <Col span={24}>
              <Button type="primary" icon={<PlusOutlined />} onClick={handleAddAndCancelAdd}>
                Add Test Case
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const resp: TagData[] = await getTags(customer.id);
        if (isAPIError(resp)) {
          console.error("Could not retrieve tags for customer");
          return;
        }
        setTagData(resp);

        setOverallAccuracy(
          resp.map(t => t.correct).reduce(sum, 0) /
            Math.max(resp.map(t => t.total).reduce(sum, 0), 1)
        );
      } catch (error) {
        setError("Error fetching tag data");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [customer]);

  useEffect(() => {
    if (!searchInput) {
      setFilteredTagData(undefined);
    }

    const filteredTagData = tagData.filter(tag => {
      return tag.name.toLowerCase().includes(searchInput.toLowerCase());
    });
    setFilteredTagData(filteredTagData);
  }, [searchInput, tagData]);

  useEffect(() => {
    const fetchValidationSetCases = async () => {
      if (!activeTag) {
        return;
      }
      setIsLoadingValidationSetCases(true);
      try {
        const resp = await getTagValidationSetCases(customer.id, activeTag.id);
        if (isAPIError(resp)) {
          console.error({
            message: "Could not retrieve validation set cases for tag",
            duration: 10,
          });
          return;
        }
        setValidationSetCases(resp);
      } catch (error) {
        setError("Error fetching validation set cases");
      } finally {
        setIsLoadingValidationSetCases(false);
      }
    };
    fetchValidationSetCases();
  }, [activeTag, customer.id]);

  const renderAccuracy = (value: number | null) => {
    if (value === null) {
      return "N/A";
    }
    return value.toLocaleString("en", { style: "percent", minimumFractionDigits: 2 });
  };

  return (
    <div style={{ margin: "24px 48px" }} id="tagaccuracy">
      <Row style={{ margin: "24px 24px 48px" }} align="bottom">
        <Col span={12}>
          <Title level={3} style={{ marginBottom: 0 }}>
            Tag Accuracy
          </Title>
        </Col>
        <Col span={12}>
          {updatedAt && (
            <Typography.Text style={{ float: "right", fontSize: "12px" }}>
              Updated: {updatedAt.toLocaleDateString()}
            </Typography.Text>
          )}
        </Col>
      </Row>
      <Row style={{ margin: "0 0 48px 0" }} gutter={16} justify="center">
        <Col span={6}>
          <Card bordered={false} className="rounded">
            <Statistic
              title={
                <Space>
                  Overall Test Case Accuracy
                  <Tooltip title="The overall accuracy percentage is the total number of correct Test Cases divided by the total number of Test Cases across all Tags">
                    <InfoCircleOutlined style={{ color: "#465fc3" }} />
                  </Tooltip>
                </Space>
              }
              value={overallAccuracy * 100}
              precision={2}
              suffix="%"
              valueStyle={{ fontSize: "24px" }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false} className="rounded">
            <Statistic
              title={
                <Space>
                  Number of Tags
                  <Tooltip title="The total number of tags in the system">
                    <InfoCircleOutlined style={{ color: "#465fc3" }} />
                  </Tooltip>
                </Space>
              }
              value={tagData.length}
              valueStyle={{ fontSize: "24px" }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false} className="rounded">
            <Statistic
              title={<Space>Target Accuracy</Space>}
              value={85}
              precision={2}
              suffix="%"
              valueStyle={{ fontSize: "24px" }}
            />
          </Card>
        </Col>
      </Row>

      <Row style={{ margin: "0 0 12px 0" }}>
        <Col span={8} offset={16}>
          <Input
            size="middle"
            allowClear
            placeholder="Search tags by name or description"
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            prefix={<SearchOutlined />}
            className="rounded"
          />
        </Col>
      </Row>
      <Spin spinning={isLoading}>
        <Row style={{ margin: "0 0 24px 0" }}>
          <Col span={24}>
            {error && <Alert type="error" message={error} className="rounded" showIcon closable />}
            <Table
              dataSource={filteredTagData ?? tagData}
              rowKey={record => record.id}
              locale={{ emptyText: "No matching tags found" }}
              pagination={false}
              expandable={{
                expandRowByClick: true,
                expandedRowClassName: () => "expanded-tag-validation-row",
                expandedRowKeys: activeTag ? [activeTag.id] : [],
                expandedRowRender: () => <ValidationSetCaseTable />,
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <DownOutlined onClick={e => onExpand(record, e)} />
                  ) : (
                    <RightOutlined onClick={e => onExpand(record, e)} />
                  ),
                onExpand: (_, record) => {
                  setActiveTag(prev => {
                    if (prev?.id !== record.id) {
                      return record;
                    }
                    return undefined;
                  });
                },
              }}
              size="small"
              className="rounded"
            >
              <Table.Column
                title="Name"
                dataIndex="name"
                key="name"
                width="250px"
                sorter={(a: TagData, b: TagData) =>
                  a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                }
                render={(value, record: TagData) => {
                  const color = tagColor(record.id);
                  const textColor = getContrastColor(color);
                  return (
                    <Tooltip title={value}>
                      <Tag
                        color={color}
                        style={{
                          color: textColor,
                          maxWidth: "230px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          borderRadius: "4px",
                        }}
                      >
                        {value}
                      </Tag>
                    </Tooltip>
                  );
                }}
              />
              <Table.Column
                title="Description"
                dataIndex="description"
                key="hypothesis"
                ellipsis={{ showTitle: false }}
                sorter={(a: TagData, b: TagData) =>
                  a.hypothesis.toLowerCase().localeCompare(b.hypothesis.toLowerCase())
                }
                render={(_, record) => (
                  <Tooltip title={record.hypothesis}>
                    <span>{record.hypothesis}</span>
                  </Tooltip>
                )}
              />
              <Table.Column
                title="Test Case Accuracy"
                dataIndex="accuracy"
                key="accuracy"
                render={renderAccuracy}
                sorter={(a: TagData, b: TagData) => (a.accuracy ?? 0) - (b.accuracy ?? 0)}
                width="200px"
              />
            </Table>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
